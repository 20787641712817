@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&family=PT+Sans+Narrow:wght@700&display=swap');
body {
  background-color: #f9f4e1;
  font-family: 'Josefin Sans', sans-serif;
}

@media screen and (min-width: 992px) {
  .container {
    padding: 0 10vh;
  }
}

@media screen and (max-width: 768px) {
  .react-calendar {
    margin-bottom: 2rem;
  }

  #events .col-md-4 {
    max-height: 470px;
  }

  #footer {
    font-size: 15px !important;
  }

  #timer {
    position: relative;
    background-image: url('./assets/phone_background.jpeg') !important;
    justify-content: start !important;
  }

  #timer .card {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 1rem;
  }
}

p {
  font-size: 16.5px;
}

.card {
  border: 1px solid black;
  border-radius: 7px;
}

.btn.btn-primary,
.btn.btn.btn-secondary {
  border: 1px solid black;
  color: black;
}

.btn.btn-primary:focus,
.btn.btn-secondary:focus {
  box-shadow: none;
}

.btn.btn-primary {
  background-color: #c0dfb1;
}

.btn.btn.btn-secondary {
  background-color: #b2aa9b;
}

input.form-control:focus,
textarea.form-control:focus,
select.form-select:focus {
  box-shadow: none;
  border-color: grey;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
}

#timer {
  min-height: 75vh;
}

#footer {
  background-color: #f2b898;
  margin-top: 4rem;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 20px;
}

.cursor {
  cursor: pointer;
}

.hidden {
  display: none;
}

#timer .flip-digit-container {
  background-color: white;
  padding: 1rem 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
  font-family: 'PT Sans Narrow', sans-serif;
  color: red !important;
}

.react-calendar {
  width: 100%;
  font-family: 'Josefin Sans', sans-serif;
}

.react-calendar abbr[title] {
  text-decoration: none;
  cursor: pointer;
}

.react-calendar {
  border: none;
  border-radius: 7px;
  border: 1px solid black;
}

.react-calendar__tile {
  border-radius: 7px;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__tile--now {
  color: #809774;
  background: none;
}

.react-calendar__tile--now abbr {
  font-weight: bold;
}

.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
}

.react-calendar__tile--active {
  background: #c0dfb1;
  color: black;
  border: 1px solid black !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #c0dfb1;
  color: black;
  border: 1px solid black;
}

.react-calendar.disabled {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
}

.react-calendar .positionRel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#timer {
  background-image: url('./assets/web_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  #events .col-md-4 {
    height: 270px;
  }
  #notes h1.title {
    margin-top: 7rem;
  }
}

#events .col-md-4 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#events .col-md-4::-webkit-scrollbar {
  display: none;
}

#events .h5.card-title {
  font-size: 1.75rem;
}

#events p.card-text {
  font-size: 20px;
}

.event-tag-badge {
  position: absolute;
  bottom: 0.7rem;
  border-radius: 7px;
  border: 1px solid black;
}

#notes .card {
  font-size: 25px;
  background: #fddb93;
}

#notes .row {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#notes .row::-webkit-scrollbar {
  display: none;
}

#notes .container i.scroll {
  position: absolute;
  z-index: 40;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}

#notes .notes-container:hover i.scroll {
  opacity: 100%;
}

#notes .card {
  height: 250px;
}

input.form-control.note-modal-title {
  font-size: 2rem;
}

input.form-control.note-modal-title,
textarea.form-control.note-modal-description {
  border: none;
}

textarea.note-modal-description {
  white-space: pre-line;
}

#notes .card p {
  font-size: 17px;
  opacity: 80%;
}

textarea.note #gallery .col-md-3 {
  padding: 1px 1px;
}

#gallery .col-md-3,
.col-xs-4 {
  padding: 2px 2px;
}

#gallery .col-md-3 .img-container {
  position: relative;
  border: 1px solid black;
  border-radius: 7px;
}

#gallery .col-md-3 img {
  border-radius: 7px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
}

#gallery .col-md-3:hover .img-overlay {
  opacity: 1;
}

#gallery .col-md-3 .img-overlay {
  border-radius: 7px;
  opacity: 0;
  top: 0;
  left: 0;
  color: white;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, transparent 0%, black 100%);
  position: absolute;
}

#gallery .col-md-3 .img-overlay.small-device {
  border-radius: 7px;
  opacity: 1;
  top: 0;
  left: 0;
  color: white;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 70%, black 100%);
  position: absolute;
}

.modal-img-detail {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: solid 1px black;
}

.img-fluid.img-detail {
  max-height: 75vh;
  object-fit: cover;
}

.modal-img-detail .modal-footer {
  border-top: none;
}

.underline-button {
  cursor: pointer;
}

.underline-button:hover {
  text-decoration: underline;
}

h1.title {
  overflow: hidden;
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 4.5rem;
  padding-bottom: 0.5rem;
}

h1.title:before,
h1.title:after {
  background-color: #000;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h1.title:before {
  right: 0.5em;
  margin-left: -50%;
}

h1.title:after {
  left: 0.5em;
  margin-right: -50%;
}
